import {
    createStore as reduxCreateStore
} from 'redux';

const reducer = (
    state, action
) => {
    if ('SET_AUTHENTICATED_USER' === action.type) {
        return Object.assign(
            {},
            state,
            {
                authenticatedUser: action.payload.authenticatedUser,
            }
        );
    }
    if ('SET_ACTIVE_LOGIN_TAB' === action.type) {
        return Object.assign(
            {},
            state,
            {
                activeLoginTab: action.payload.activeLoginTab,
            }
        );
    }
    if ('SET_APPLICATION' === action.type) {
        return Object.assign(
            {},
            state,
            {
                application: action.payload.application,
            }
        );
    }
    if ('SET_JURY_VOTINGS' === action.type) {
        return Object.assign(
            {},
            state,
            {
                juryVotings: action.payload.juryVotings,
            }
        );
    }
    return state;
};

const initialState = {
    activeLoginTab: 'register',
    authenticatedUser: null,
    application: null,
    juryVotings: [],
};

const createStore = () => {
    return reduxCreateStore(
        reducer,
        initialState
    );
};
export default createStore;
