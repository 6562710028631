// global styles
import './src/styles/normalize.css';
import './src/styles/variables.css';
import './src/styles/grid.css';
import './src/styles/global.css';
import './src/styles/form.css';

// IE11 stuff
// import './src/styles/ie.css';
// import cssVars from 'css-vars-ponyfill';
// import '@babel/polyfill';

// enable redux store
import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

// DOM Ready equivalent
// use this for e.g. polyfills
export function onInitialClientRender () {
    // polyfill NodeList iterator (IE 11)
    if ('function' !== typeof Array.prototype.forEach) {
        Array.prototype.forEach = function (callback) {
            for (var i = 0; i < this.length; i++) {
                callback.apply(
                    this,
                    [
                        this[i],
                        i,
                        this,
                    ]
                );
            }
        };
    }

    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    // Polyfill for CustomEvent (IE 11)
    (function () {
        if ('function' === typeof window.CustomEvent) return false;

        function CustomEvent(
            event, params
        ) {
            params = params || {
                bubbles: false, cancelable: false, detail: undefined,
            };
            var evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(
                event,
                params.bubbles,
                params.cancelable,
                params.detail
            );
            return evt;
        }

        CustomEvent.prototype = window.Event.prototype;

        window.CustomEvent = CustomEvent;
    })();

    // cssVars();
    // var script = document.createElement('script'); script.src = '/scripts/css-vars-ponyfill.js'; document.body.appendChild(script);
}
